$baseColor: #003049;

.contact-form {
    margin-bottom: 5rem;
    background-color: whitesmoke;
    padding: 2rem;
    border-radius: 0.3rem;
    max-width: 50rem;
    color: black;
}

.customButton {
    background-color: white;
    border-color: black;
    color: black
}

.contact_section {
    margin-top: 2rem;
}