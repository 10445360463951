$baseNavColor: #1b1b2f;

.frontheader {
    background-color: #003049;
    // background-image: url("https://images.unsplash.com/photo-1504639725590-34d0984388bd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1674&q=80");
    min-height: 500;
    display: flex;
    background-image: linear-gradient(#003049, #004c59); // good option.
    align-items: center;
    min-width: 90vw;    
    flex-direction: column;
    padding: 10px;
}

.circularimage {
    width: 150px;
    height: 150px;
    border-radius: 100px;
    margin-top: 25px;
}

@media screen and (max-device-width: 420px){
    .frontheader {
        min-height: 60vh;
        min-width: 100%;
    }
    .resumeBtn {
        min-width: 40vw;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}