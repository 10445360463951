$baseColor: #1d3557;
$baseNavColor: #1b1b2f;
$secondaryColor: red;

.aboutSection {
    white-space: inherit;
    margin: 2rem;
    padding: 1rem;
    text-align: justify;
    text-justify: distribute;
    word-wrap: break-all;
    background-color: white;
}

.customThumbnail {
    display: block;
    margin-top: 0.5rem;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    height: auto;
    max-height: 250px;
    width: auto;
    max-width: 250px;
    
}
